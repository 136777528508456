.css-1mpfsae {
    width: 100%;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: absolute!important;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    z-index: 999!important;
}

.MuiLoadingButton-loadingIndicator {
    color: rgb(255 255 255)!important;
}

.darkTheme .MuiLoadingButton-loadingIndicator {
    color: rgb(38 37 37 / 46%)!important;
    background: rgb(15, 237, 178);
}

textarea::-webkit-scrollbar {
    width: 4px;
}

textarea::-webkit-scrollbar-track {
    background: #f1f1f1; /* cor do fundo da trilha da barra de rolagem */
}

textarea::-webkit-scrollbar-thumb {
    background-color: #a9a8a8; /* cor do polegar (parte deslizante) da barra de rolagem */
    border-radius: 6px; /* borda arredondada do polegar */
}

textarea::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* cor do polegar ao passar o mouse por cima */
}

/* Estilo para o textarea */
textarea {
    width: 300px; /* largura desejada */
    height: 150px; /* altura desejada */
    overflow: auto; /* Adiciona a barra de rolagem automaticamente quando necessário */
}

.DraftEditor-editorContainer {
    background-color: hsla(0, 0%, 100%, 0);
    border-left: .1px solid transparent;
    position: relative;
    z-index: 1;
}

.public-DraftEditorPlaceholder-root {
    color: #9197a3;
    position: absolute;
    z-index: 1;
}
