
.Draftail-Editor{
    border:none;
    background-color: #ffffff!important;
    padding: 0;
    position: relative;
}


.Draftail-Toolbar {
    z-index: 1000;
    width: 100%;
    position: fixed;
    bottom: 0;
    /*left: 70px;*/
    padding: 0.25rem;
    padding-bottom: 12px!important;
    /*padding-left: 400px!important;*/
    /*text-align: justify;*/
    /*justify-content: center;*/
    /*justify-items: center;*/
    background-color: #ffffff !important;
    color: #999999FF !important;

    border: none;
    border-radius: 0;
    display: flex;
    justify-content: left;
}

.Draftail-ToolbarButton {
    border: none;
    background: transparent;
    font-size: 1.0rem!important;
    font-weight: 600;
    padding: 0.5rem;
}

.Draftail-ToolbarButton--active {
    background-color: rgba(0,0,0,.1) !important;
    border: 1px solid rgba(0,0,0,.2) !important;
}

.Draftail-Toolbar--bottom {
    border: 0 !important;
    background: transparent !important;
    color: #999999FF !important;
}

.Draftail-ToolbarGroup::before {
    background-color: #ffffff !important;
}

.Draftail-Editor .public-DraftEditor-content {
    font: normal normal 300 18px/27px Inter !important;
    min-height: 8rem;
    padding: 0 !important;
}

.public-DraftStyleDefault-block {
    padding-bottom: 0.8rem;
    line-height: 2rem;
}

[data-draftail-balloon]::after, [data-draftail-balloon]::before {
    display: none !important
}

.public-DraftEditorPlaceholder-root {
    padding: 0 !important;
    margin-top: 2px !important;
    font: normal normal 300 18px/27px Inter !important;
    color: #5b5b5b !important;
}

.public-DraftEditorPlaceholder-inner {
    color: #757575 !important;
}

.Draftail-Editor .DraftEditor-root {
    overflow: hidden !important;
}

#item-card-container {
    transition: all ease-in 0.15s;
    padding-bottom: 100px;
}

* {
    box-sizing: border-box;
}

.Draftail-Editor--focus .Draftail-Toolbar {
    z-index: 1000;
    width: 100%;
    position: fixed;
    bottom: 0;
    /*left: 70px;*/
    padding: 0.25rem;
    padding-bottom: 12px!important;
    background-color: #ffffff;
    color: #333;
    border: none;
    border-radius: 0;
    top: auto;
}
.css-ajiu83{
    margin-left: 50px!important;
    min-width: 600px!important;
    width: 100%!important;
}

/* DARK THEME */

.darkTheme .Draftail-Editor{
    border:none;
    background-color: #252525!important;
    padding: 0;
    position: relative;
    color: #fff;
}

.darkTheme .DraftEditor-editorContainer{
    /*background-color: #252525;*/
}

.darkTheme .public-DraftStyleDefault-ltr{
    color:#fff;
}

.darkTheme .Draftail-Toolbar {
    z-index: 1000;
    width: 100%;
    position: fixed;
    bottom: 0;
    /*left: 70px;*/
    padding: 0.25rem;
    padding-bottom: 12px!important;
    /*padding-left: 400px!important;*/
    /*text-align: justify;*/
    /*justify-content: center;*/
    /*justify-items: center;*/
    background-color: #252525!important;
    color: #fff;
    border: none;
    border-radius: 0;
}

/*.darkTheme .DraftEditor-editorContainer {*/
/*    z-index: 1;*/
/*}*/

/*.darkTheme .public-DraftEditorPlaceholder-root {*/
/*    z-index: 2;*/
/*}*/

/*.darkTheme .Draft-Editor--focus .DraftEditor-editorContainer {*/
/*    z-index: 2!important;*/
/*}*/

/*.darkTheme .public-DraftEditorPlaceholder-inner {*/
/*    color: #FFFFFF!important;*/
/*}*/

.darkTheme .public-DraftEditorPlaceholder-inner {
    color: rgba(255, 255, 255, 0.88) !important;
}

.darkTheme .public-DraftStyleDefault-orderedListItem:before {
    color: #ffffff!important;
}

.darkTheme .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    color: #ffffff!important;
}

/* DARK THEME */

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 1.8em;
}

.Draftail-block--text-align-left > div {
    text-align: left;
}

.Draftail-block--text-align-center > div {
    text-align: center;
}

.Draftail-block--text-align-right > div {
    text-align: right;
}

.Draftail-block--text-align-justify > div {
    text-align: justify;
}

.isMobile .Draftail-ToolbarButton {
    border: none;
    padding: 0.35rem!important;
}


.isMobile .Draftail-Toolbar {
    left: 0px!important;
    text-align: center!important;
    padding-top: 5px!important;
    padding-bottom: 5px!important;
    background-color: #f3f3f3 !important;
    display: flex;
    justify-content: left;
}

.isMobileDarkTheme .Draftail-Toolbar {
    left: 0px!important;
    text-align: center!important;
    padding-top: 5px!important;
    padding-bottom: 5px!important;
    background-color: #3a3541!important;
}

.isMobileDarkTheme .Draftail-ToolbarButton {
    color: white!important;
}

